import ApiService from "@/common/api/api.service";

const local0 = "extrato-transacao/";
const local1 = "transacoes/";

const ExtratoTransacao = {
  listar: function (filtro, paginacao = 1, porPagina = 10) {
    let link = `${local0}${local1}analitico?NumeroDaPagina=${paginacao}&TamanhoDaPagina=${porPagina}`;
    link = link.concat(`${filtro.estabelecimentoId ? `&EstabelecimentoId=${filtro.estabelecimentoId}` : ''}`);
    link = link.concat(`${filtro.termo ? `&TermoPesquisa=${filtro.termo}` : ''}`);
    link = link.concat(`${filtro.dataInicial ? `&DataInicial=${filtro.dataInicial}` : ''}`);
    link = link.concat(`${filtro.dataFinal ? `&DataFinal=${filtro.dataFinal}` : ''}`);
    link = link.concat(`${filtro.situacao ? `&Situacao=${filtro.situacao}` : ''}`);
    return ApiService.get(link);
  },
  dashboard: function (filtro) {
    let link = `${local0}${local1}sumarizado?1=1`;
    link = link.concat(`${filtro.estabelecimentoId ? `&EstabelecimentoId=${filtro.estabelecimentoId}` : ''}`);
    link = link.concat(`${filtro.termo ? `&TermoPesquisa=${filtro.termo}` : ''}`);
    link = link.concat(`${filtro.dataInicial ? `&DataInicial=${filtro.dataInicial}` : ''}`);
    link = link.concat(`${filtro.dataFinal ? `&DataFinal=${filtro.dataFinal}` : ''}`);
    link = link.concat(`${filtro.situacao ? `&Situacao=${filtro.situacao}` : ''}`);
    return ApiService.get(link);
  },
  buscar: function (id, recorrencia) {
    return ApiService.get(`${local0}${local1}detalhe?OrigemId=${id}&TipoLinkPagamento=${recorrencia ? 'Recorrencia' : 'AVistaParcelado'}`);
  },
  rejeitar: function (form) {
    return ApiService.put(`${local0}rejeitar`, form);
  },
  aprovar: function (form) {
    return ApiService.put(`${local0}aprovar`, form);
  },
  forcarAtualizacaoStatusCobrancaUnica: function (id) {
    return ApiService.put(`${local0}forcar-atualizacao-status-unica/${id}`)
  },
  forcarAtualizacaoStatusCobrancaParcelada: function (id) {
    return ApiService.put(`${local0}forcar-atualizacao-status-parcelada/${id}`)
  },
};

export default ExtratoTransacao;
