<template>
  <div>
    <div class="d-flex align-items-center mb-2">
      <template v-if="podeCancelar">
        <b-button
          id="btn-aprovar-cancelamento"
          v-if="permissao.editar"
          @click="abrirModalCancelamento(true)"
          variant="outline-valorem-ciano"
          class="mr-2"
        >
          <div class="d-flex align-items-center">
            <i class="fas fa-thumbs-up mr-2"></i>
            Aprovar cancelamento
          </div>
        </b-button>
        <b-button
          id="btn-rejeitar-cancelamento"
          v-if="permissao.editar"
          @click="abrirModalCancelamento(false)"
          variant="outline-valorem-vermelho"
          class="mr-3"
        >
          <div class="d-flex align-items-center">
            <i class="fas fa-thumbs-down mr-2"></i>
            Rejeitar cancelamento
          </div>
        </b-button>
      </template>
      <b-button
        id="btn-visualizar"
        @click="abrirModalSolicitacao()"
        v-if="podeVerSolicitacao"
        class="mr-2"
        variant="valorem-ciano"
      >
        {{ $t("SOLICITACOES.VER_SOLICITACOES") }}
      </b-button>
    </div>
    <valorem-tabela
      id="tabela-solicitacoes"
      sem-selecionar-todos
      v-model="tabela.dados"
      :colunas="tabela.colunas"
      ref="tabela"
    >
      <template #cell(tipoSolicitacao)="dados">
        <span :class="`text-${dados.value?.variante} font-weight-bold text-capitalize`">
          {{ dados.value?.text }}
        </span>
      </template>
      <template #cell(statusSolicitacao)="dados">
        <span :class="`text-${dados.value?.variante} font-weight-bold text-capitalize`">
          {{ dados.value?.text }}
        </span>
      </template>
    </valorem-tabela>
    <modal-cancelamento-link ref="modalLink" @atualizar="atualizar" />
  </div>
</template>
<script>
import helpers from "@/common/utils/helpers";
import ModalCancelamentoLink from "./ModalCancelamentoLink.vue";
import LinkPagamentoService from "@/common/services/link_pagamento/link_pagamento.service";
export default {
  props: {
    linkPagamentoId: {
      required: true,
    },
    dadosTela: {},
  },
  components: {
    ModalCancelamentoLink,
  },
  data: function () {
    return {
      tabela: {
        dados: [],
        colunas: [
          { key: "seletor", label: "" },
          {
            key: "tipoSolicitacao",
            label: this.$t("SOLICITACOES.TABELA.TIPO_SOLICITACAO"),
            formatter: (v) => {
              if (v) return helpers.getEnum(v);
            },
          },
          {
            key: "dataSolicitacao",
            label: this.$t("SOLICITACOES.TABELA.DATA_SOLICITACAO"),
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v.dataSolicitacao, true);
            },
          },
          { key: "motivo", label: this.$t("SOLICITACOES.TABELA.MOTIVO_CANCELAMENTO") },
          {
            key: "dataResposta",
            label: this.$t("SOLICITACOES.TABELA.DATA_PARECER"),
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v.dataSolicitacao, true);
            },
          },
          { key: "respostaValorem", label: this.$t("SOLICITACOES.TABELA.PARECER") },
          {
            key: "statusSolicitacao",
            label: "",
            formatter: (v) => {
              return helpers.getEnum(v);
            },
          },
        ],
      },
    };
  },
  mounted() {},
  methods: {
    abrirModalCancelamento: function (aprovar) {
      let solicitacao = this.dadosSelecionados[0];
      this.$refs.modalLink.abrirModal({}, solicitacao, aprovar);
    },
    abrirModalSolicitacao: function () {
      let solicitacao = this.dadosSelecionados[0];
      this.$refs.modalLink.abrirModalDesabilitado({}, solicitacao);
    },
    buscarSolicitacoes: function () {
      LinkPagamentoService.buscarSolicitacoes(this.linkPagamentoId).then((res) => {
        this.tabela.dados = res.data.data.solicitacoes;
      });
    },
    atualizar: function () {
      this.$refs.modalLink.fecharModal();
      this.buscarSolicitacoes();
    },
  },
  computed: {
    ehAdmin: function () {
      return this.$store.getters.ehAdmin;
    },
    dadosSelecionados: function () {
      return this.tabela.dados.filter((el) => {
        return el.selecionado;
      });
    },
    podeCancelar: function () {
      if (this.dadosSelecionados.length != 1) return false;

      return this.dadosSelecionados[0].statusSolicitacao == "Pendente" && this.ehAdmin;
    },
    podeVerSolicitacao: function () {
      if (this.dadosSelecionados.length != 1) return false;
      return this.permissao.r;
    },
    permissao: function () {
      return helpers.validarAcesso("LinkPagamento");
    },
  },
  watch: {
    linkPagamentoId: {
      handler() {
        this.buscarSolicitacoes();
      },
    },
  },
};
</script>
<style scoped lang="scss">
.legenda {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
h6 {
  margin-bottom: 0px;
}
.card-bordered {
  border-width: 1px !important;
}
</style>
