<template lang="">
    <div>
        <b-card>          
            <div class="row">
                <input-currency class="col-12 col-md-4" :label="$t('EXTRATO_TRANSACAO.VALOR')" disabled v-model="form.valorTotalCobranca"/>
                <input-text class="col-12 col-md-4" :label="$t('EXTRATO_TRANSACAO.LINK')" disabled v-model="form.link"/>
                <input-text class="col-12 col-md-4" :label="$t('EXTRATO_TRANSACAO.TIPO')" readonly v-model="form.tipo">
                  <template #append>
                    <b-button @click="abrirAssinatura" v-if="form.tipo == 'Recorrencia' && permissao.visualizar && form.assinaturaId" class="ml-2" variant="outline-valorem-ciano">
                      {{$t('EXTRATO_TRANSACAO.CONSULTAR_ASSINATURA')}}
                    </b-button>
                  </template>
                </input-text>
                <input-text class="col-12 col-md-6" :label="$t('EXTRATO_TRANSACAO.CLIENTE')" disabled v-model="form.cliente"/>
                <input-text class="col-12 col-md-6" :label="$t('EXTRATO_TRANSACAO.ESTABELECIMENTO')" disabled v-model="form.estabelecimento"/>
            </div>
        </b-card>  
        <b-card class="mt-3">          
            <div class="row">
                <input-text class="col-12 col-md-4" :label="$t('EXTRATO_TRANSACAO.MEIO')" disabled v-model="form.meioPagamento"/>
                <input-text class="col-12 col-md-4" :label="$t('EXTRATO_TRANSACAO.VALOR_TRANSACAO')" disabled v-model="form.valorTransacao"/>
                <input-text class="col-12 col-md-4" :label="$t('EXTRATO_TRANSACAO.DATA')" disabled v-model="form.dataCriacao"/>
                <input-text class="col-12 col-md-2" :label="$t('EXTRATO_TRANSACAO.PARCELAS')" disabled v-model="form.parcelas"/>
                <input-text class="col-12 col-md-10" :label="$t('EXTRATO_TRANSACAO.ADQUIRINTE')" disabled v-model="form.nomeAdquirinte"/>
                <!-- <input-text class="col-12 col-md-6" :label="$t('EXTRATO_TRANSACAO.PORTADOR')" disabled v-model="form.portador"/> -->
                <input-text-area class="col-12" :label="$t('EXTRATO_TRANSACAO.MENSAGEM_ERRO')" disabled v-model="form.mensagemErro"/>
                <input-text class="col-12 col-md" :label="$t('EXTRATO_TRANSACAO.COD_AUTORIZACAO')" disabled v-model="form.codigoAutorizacao"/>
                <input-text class="col-12 col-md" :label="$t('EXTRATO_TRANSACAO.BANDEIRA')" disabled v-model="form.bandeiraCartao"/>
                <input-text class="col-12 col-md" :label="$t('EXTRATO_TRANSACAO.CARTAO')" disabled v-model="form.numeroCartao"/>
                <input-text class="col-12 col-md" :label="$t('EXTRATO_TRANSACAO.NSU')" disabled v-model="form.nsu"/>
            </div>
        </b-card>   
        <b-card class="mt-3" v-if="form.tipo == 'Recorrencia' && form.assinaturaId">          
            <div class="mb-4 flex-between-responsive fill">
                <h6 class="text-center">{{$t('EXTRATO_TRANSACAO.SOLICITAR_CANCELAMENTO')}}</h6>
                <b-button variant="outline-valorem-vermelho" v-if="permissao.editar" @click="abrirCancelamento">
                  {{$t('EXTRATO_TRANSACAO.CANCELAR')}}
                </b-button>
            </div>
            <solicitacoes-cobranca ref="solicitacoesCobranca" :cobranca-id="form.origemId"/>            
            <modal-cancelamento-cobranca ref="modalCobranca" @atualizar="atualizarCobranca" />  
        </b-card> 
        <b-card class="mt-3" v-if="form.tipo != 'Recorrencia'">          
            <div class="mb-4 flex-between-responsive fill">
                <h6 class="text-center">{{$t('EXTRATO_TRANSACAO.SOLICITAR_CANCELAMENTO')}}</h6>
                <b-button variant="outline-valorem-vermelho" v-if="permissaoLink.editar" @click="abrirCancelamentoLink">
                  {{$t('EXTRATO_TRANSACAO.CANCELAR')}}
                </b-button>
            </div>
            <solicitacoes-link ref="solicitacoesLink" :link-pagamento-id="form.origemId"/>            
            <modal-cancelamento-link ref="modalLink" @atualizar="atualizarLink" />  
        </b-card> 
    </div>
</template>
<script>
import ExtratoTransacaoService from "@/common/services/extrato_transacao/extrato_transacao.service";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import { InputText, InputCurrency, InputTextArea } from "@/components/inputs"
import helpers from "../../common/utils/helpers";
import SolicitacoesCobranca from './components/SolicitacoesAssinatura.vue';
import SolicitacoesLink from './components/SolicitacoesLink.vue';
import ModalCancelamentoCobranca from "@/views/assinaturas/components/ModalCancelamentoCobranca.vue";
import ModalCancelamentoLink from "./components/ModalCancelamentoLink.vue";
export default {
    props : ['id'],
    components : {
        InputText,
        InputCurrency,
        InputTextArea,
        SolicitacoesCobranca,
        ModalCancelamentoCobranca,
        SolicitacoesLink,
        ModalCancelamentoLink
    },
    data : function(){
        return {
            form : {},
        }
    },
    methods : {
        abrirAssinatura : function(){
            window.location.href = `/assinaturas/${this.form.assinaturaId}`;
        },
        cancelar : function(){
            this.$router.push({name : "extrato-transacao"});
        },
        buscar : function(){
            this.$store.dispatch(START_LOADING);
            ExtratoTransacaoService.buscar(this.id, this.$route.name == "extrato-transacao-detalhes-recorrencia").then(res => {
                let form = res.data.data;
                this.form = form;
                this.form.valorTotalCobranca = helpers.formatarValor(form.valorTotalCobranca)
                this.form.valorTransacao = helpers.formatarValor(form.valorTransacao)
                this.form.dataCriacao = helpers.formatarDataBr(form.dataCriacao, true)
            }).finally(() => {
                this.$store.dispatch(STOP_LOADING);
            })
        },
        abrirCancelamento : function(){
            let form = this.form;   
            form.dataCobranca = this.form.dataCriacao;
            form.valor = helpers.removerValor(this.form.valorTransacao);   
            form.id = this.form.origemId;            
            this.$refs.modalCobranca.abrirModal(form);
        },
        abrirCancelamentoLink : function(){
            let form = this.form;   
            form.dataCobranca = this.form.dataCriacao;
            form.valor = helpers.removerValor(this.form.valorTransacao);   
            form.id = this.form.origemId;            
            this.$refs.modalLink.abrirModal(form);
        },
        atualizarCobranca : function(){
            this.$refs.modalCobranca.fecharModal();
            this.$refs.solicitacoesCobranca.buscarSolicitacoes();
        },
        atualizarLink : function(){
            this.$refs.modalLink.fecharModal();
            this.$refs.solicitacoesLink.buscarSolicitacoes();
        }
    },
    mounted(){
        this.$store.dispatch(DEFINIR_BREADCRUMB, [
            { titulo: this.$t("EXTRATO_TRANSACAO.TITULO_LISTA"), to : '/extrato_transacao' },
            { titulo: this.$t(`EXTRATO_TRANSACAO.TITULO_FORMULARIO`) },
        ]);
            
        this.buscar();        
    },
    computed : {
        permissao: function () {
          return helpers.validarAcesso("Assinatura");
        },
        permissaoLink: function () {
          return helpers.validarAcesso("LinkPagamento");
        },
    }

}
</script>
<style scoped lang="scss">
    .legenda{
        width: 15px;
        height: 15px;
        border-radius: 50%;
    }
    h6{
        margin-bottom: 0px;
    }
    
</style>